:root{
    --font-color-white: #FFFFFF;
    --font-color-black: #020F14;
}


@media only screen and (min-width : 320px) and (max-width : 480px) {

    /* ====================================================== */

    /* ---------- Navbar ---------- */

    .nav-link{
        margin-left: 0;
        background-color: #FFFFFF;
        color: var(--font-color-black);
    }

    .nav-link:hover{
        color: var(--font-color-black);
    }
    

    /* ---------- End Navbar ---------- */

    /* ====================================================== */


    /* ====================================================== */

    /* ---------- Main Content ---------- */
    
    .heading-title .title-one h1{
        margin-top: 20%;
        font-size: 40px;
        font-weight: 600;
    }
    
    .heading-title .title-two h5{
        font-size: 24px;
        font-weight: 500;
    }
    
    .heading-title .title-three h5{
        margin-top: 4%;
        font-size: 18px;
        font-weight: 200;
    }

    /* ---------- End Main Content ---------- */

    /* ====================================================== */


    /* ====================================================== */

    /* ---------- Problem Content ---------- */

    #problem-content{
        margin-top: 12%;
    }

    #problem-content h1{
        font-size: 36px;
    }

    #problem-content p{
        font-size: 18px;
    }

    #problem-content > .container .row:nth-child(3){
        margin-top: 4%;
    }

    #problem-content .problem-card{
        margin-top: 4%;
    }

    #problem-content .card{
        height: 100%;
    }

    #problem-content .card .problem-icon{
        width: 40px;
    }

    #problem-content .card .card-title{
        font-size: 20px;
    }

    #problem-content .card .card-text{
        font-size: 18px;
    }

    /* ---------- End Problem Content ---------- */

    /* ====================================================== */


    /* ====================================================== */

    /* ---------- Quotes Content ---------- */

    #quotes-developing-content{
        margin-top: 12%;
    }

    #quotes-developing-content .container .row{
        padding: 10% 0;
    }

    #quotes-developing-content .quotes-one h1{
        font-size: 36px;
    }

    #quotes-developing-content .quotes-two h1{
        margin-top: 2%;
        font-size: 36px;
    }

    #quotes-developing-content .quotes-three p{
        font-size: 18px;
    }

    /* ---------- End Quotes Content ---------- */

    /* ====================================================== */


    /* ====================================================== */

    /* ---------- About Us Content ---------- */

    #about-us-content{
        margin-top: 12%;
    }

    #about-us-content .row{
        flex-direction: column-reverse;
    }

    #about-us-content .about-us-video .youtube-aboutus{
        margin-top: 6%;
        top: 0 !important;
        left: 50% !important;
        transform: translate(-50%) !important;
    }

    #about-us-content .about-us-describe{
        margin: auto 0;
    }

    #about-us-content .about-us-describe hr{
        width: 14%;
        height: 2px;
        background-color: #012E80;
    }

    #about-us-content .about-us-describe h5{
        margin-top: 2%;
        font-weight: 600;
        color: var(--font-color-black);
    }

    #about-us-content .about-us-describe h2{
        margin-top: 2%;
        font-weight: 600;
        color: var(--font-color-black);
    }

    #about-us-content .about-us-describe p{
        margin-top: 2%;
        font-size: 18px;
        color: var(--font-color-black);
    }

    /* ---------- End About Us Content ---------- */

    /* ====================================================== */


    /* ====================================================== */

    /* ---------- Vision Missions Content ---------- */

    #vision-missions-content{
        margin-top: 12%;
        margin-bottom: 6%;
    }
    
    #vision-missions-content .vision-missions-describe h5{
        font-size: 20px;
    }
    
    #vision-missions-content .vision-missions-describe p{
        font-size: 18px;
    }

    #vision-missions-content .selected-button-outlook{
        margin-top: 10%;
    }

    #vision-missions-content .selected-button-outlook > :nth-child(1),
    #vision-missions-content .selected-button-outlook > :nth-child(2),
    #vision-missions-content .selected-button-outlook > :nth-child(3),
    #vision-missions-content .selected-button-outlook > :nth-child(4){
        justify-content: start !important;
    }
    
    #vision-missions-content .selected-button-outlook button{
        font-size: 16px;
        width: 100%;
    }

    /* ---------- End Vision Missions Content ---------- */

    /* ====================================================== */


    /* ====================================================== */

    /* ---------- Solutions Content ---------- */

    #solutions-content{
        margin-top: 12%;
    }

    #solutions-content .solutions-title h1{
        font-size: 36px;
    }
    
    #solutions-content .solutions-title p{
        font-size: 18px;
    }
    
    #solutions-content .solutions-we-are{
        margin-top: 8%;
    }

    #solutions-content .solutions-we-are .solution-selection{
        padding: 3% 6%;
    }
    
    .solutions-detail-title{
        height: 100%;
        display: flex;
        align-items: flex-start;
        flex-grow: 1;
    }
    
    #solutions-content .solutions-detail-title h1{
        font-size: 28px;
        font-weight: 600;
        color: var(--font-color-white);
        margin: 0;
    }
    
    #solutions-content .solutions-detail-button{
        bottom: 0;
    }
    
    #solutions-content .solutions-detail-button button{
        width: 50%;
    }

    /* ---------- End Solutions Content ---------- */

    /* ====================================================== */


    /* ====================================================== */

    /* ---------- Awards & Recognitions Content ---------- */

    #awards-recognitions-content{
        margin-top: 12%;
    }
    
    #awards-recognitions-content .awards-recognitions-title h1{
        font-size: 36px;
    }
    
    #awards-recognitions-content .awards-recognitions-title p{
        font-size: 18px;
        font-weight: 300;
    }
    
    #awards-recognitions-content hr{
        margin-top: 2%;
    }
    
    #awards-recognitions-content .awards-describe{
        margin-top: 2%;
    }
    
    #awards-recognitions-content .awards-describe h1{
        font-size: 24px;
    }

    #awards-recognitions-content .awards-describe img{
        width: 100px;
        height: auto;
    }
    
    #awards-recognitions-content button{
        margin-top: 16%;
        height: 40px;
        width: 40%;
    }

    /* ---------- End Awards & Recognitions Content ---------- */

    /* ====================================================== */


    /* ====================================================== */

    /* ---------- SDGS Content ---------- */


    #sdgs-title-content{
        margin-top: 12%;
        position: relative;
        overflow: hidden;
        padding: 16% 0 16% 0;
    }
    
    #sdgs-title-content h1{
        font-size: 34px;
    }
    
    #sdgs-title-content p{
        margin-top: 2%;
        color: var(--font-color-white);
        text-align: center;
        font-size: 18px;
    }
    
    #sdgs-title-content .btn-sdgs-title-solutions{
        margin-top: 4%;
        width: 100%;
    }
    
    
    #sdgs-title-content .btn-sdgs-title-learn-more{
        margin-top: 4%;
        width: 100%;
    }
    
    /* ---------- End SDGS Content ---------- */
    
    /* ====================================================== */


    /* ====================================================== */

    /* ---------- SDGS Describe Content ---------- */

    #sdgs-describe-content{
        margin-top: 12%;
    }

    #sdgs-describe-content .card{
        width: 80%;
        margin: 0 auto;
    }

    #sdgs-describe-content .card-text{
        font-size: 20px;
        text-align: center;
    }

    /* ---------- End SDGS Describe Content ---------- */

    /* ====================================================== */


    /* ====================================================== */

    /* ---------- Impact Content ---------- */

    #impact-content{
        margin-top: 12%;
    }

    #impact-content h1{
        font-size: 28px;
    }

    #impact-content p{
        margin-top: 4%;
        font-size: 18px;
    }

    #impact-content .btn-see-impact{
        margin-top: 8%;
    }

    #impact-content .search-icon-impact{
        margin-top: -2%;
        width: 18px;
    }

    #impact-content span{
        font-size: 18px;
        color: var(--font-color-blue);
        font-weight: 600;
        margin-left: 2%;
    }

    #impact-content .card-impact{
        margin-top: 6%;
    }
    
    #impact-content .card-impact-detail{
        margin-top: 4%;
    }

    #impact-content .card{
        height: 100%;
        border: none;
        transition: 0.3s all ease-in-out;
    }

    #impact-content .card:hover{
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    }

    #impact-content .card-title{
        font-size: 30px;
        text-align: center;
        font-weight: 600;
    }

    #impact-content .card-text{
        text-align: center;
        font-size: 18px;
    }

    #impact-content .card-text span{
        font-size: 12px;
        color: var(--font-color-black);
        margin-left: -1%;
    }

    /* ---------- End Impact Content ---------- */

    /* ====================================================== */


    /* ====================================================== */

    /* ---------- Client Content ---------- */

    #client-content{
        margin-top: 12%;
    }
    
    #client-content h5{
        font-size: 20px;
    }
    
    #client-content .client-logo{
        margin-top: 1%;
        margin-bottom: 1%;
    }
    
    #client-content .client-logo img{
        width: 100px;
        height: 36px;
    }

    /* ---------- End Client Content ---------- */

    /* ====================================================== */


    /* ====================================================== */

    /* ---------- Media Content ---------- */

    #media-content{
        margin-top: 12%;
    }

    #media-content h1{
        font-size: 36px;
    }

    #media-content p{
        font-size: 18px;
    }

    #media-content .media-card .media-describe{
        margin-top: 6%;
    }

    #media-content .media-card .media-image{
        border-radius: 30px 30px;
    }

    #media-content .media-card .card-title{
        font-weight: 600;
    }

    /* ---------- End Media Content ---------- */

    /* ====================================================== */


    /* ====================================================== */

    /* ---------- Contact Content ---------- */

    #contact-content{
        margin-top: 12%;
    }

    #contact-content .contact-describe{
        padding: 8%;
    }

    #contact-content h1{
        font-size: 30px;
    }

    #contact-content .btn-contact{
        margin-top: 12%;
        width: 40%;
    }

    /* ---------- End Contact Content ---------- */

    /* ====================================================== */


    /* ====================================================== */

    /* ---------- Footer Content ---------- */

    #footer-content span{
        color: var(--font-color-white);
        font-size: 20px;
        margin-left: 0;
    }

    #footer-content img{
        width: 15%;
    }

    #footer-content .footer-describe{
        padding: 6% 4%;
    }

    #footer-content .company-describe{
        text-align: center;
    }

    /* ---------- End Footer Content ---------- */

    /* ====================================================== */


    /* ====================================================== */
    /* ====================================================== */

    /* ---------- SOLUTION PAGE ---------- */

    /* ====================================================== */

    /* ---------- Solution Title ---------- */

    #solution-title-content{
        margin-top: 30%;
        padding: 8% 0;
    }

    #solution-title-content h1{
        font-size: 36px;
    }

    #solution-title-content p{
        font-size: 18px;
    }

    #solution-title-content .btn-additional{
        margin-top: 4%;
    }

    #solution-title-content .btn-chooseus{
        width: 100%;
    }
    #solution-title-content .btn-contactus{
        width: 100%;
    }

    /* ---------- End Solution Title ---------- */

    /* ====================================================== */


    /* ====================================================== */

    /* ---------- Solution Card Content ---------- */

    #card-solution-content{
        margin-top: 4%;
    }

    #card-solution-content .card-solution-describe{
        margin-top: 4%;
    }

    #card-solution-content .card-solution-describe .solution-icon{
        width: 40px;
        border-radius: 50%;
        margin-top: 4%;
        margin-left: 4%;
        padding: 1%;
    }

    #card-solution-content .card-solution-describe .card-title{
        margin-top: 2%;
        font-weight: 600;
        color: var(--font-color-black);
        font-size: 22px;
    }

    #card-solution-content .card-solution-describe .card-text{
        color: var(--font-color-black);
    }

    #card-solution-content .card-solution-describe .card-link{
        color: var(--font-color-black);
        font-weight: 600;
        list-style: none;
        text-decoration: none;
    }

    /* ---------- End Solution Card Content ---------- */

    /* ====================================================== */


    /* ====================================================== */

    /* ---------- Partner Content ---------- */

    #partner-content{
        margin-top: 12%;
    }

    #partner-content h1{
        font-size: 36px;
        font-weight: 600;
    }

    /* ---------- End Partner Content ---------- */

    /* ====================================================== */


    /* ====================================================== */

    /* ---------- Solution Card Content ---------- */

    #card-point-solution{
        margin-top: 8%;
    }

    #card-point-solution .point-describe{
        margin-top: 4%;
    }

    /* ---------- End Solution Card Content ---------- */

    /* ====================================================== */


    /* ====================================================== */

    /* ---------- Solution Detail Title Content ---------- */

    #solution-detail-title-content{
        margin-top: 12%;
    }

    #solution-detail-title-content h6{
        margin-top: 20%;
    }

    #solution-detail-title-content img{
        margin-top: 12%;
        width: 100% !important;
    }

    #solution-detail-title-content h1{
        margin-top: 8%;
        font-size: 36px;
    }

    #solution-detail-title-content .btn-learn-more{
        margin-top: 6%;
        width: 50%;
    }

    /* ---------- End Solution Detail Title Content ---------- */

    /* ====================================================== */


    /* ====================================================== */

    /* ---------- Solution Detail Product Content ---------- */

    #solution-detail-product-content{
        margin-top: 24%;
    }

    #solution-detail-product-content .product-title h1{
        font-size: 24px;
    }

    #solution-detail-product-content .product-detail{
        margin-top: 12%;
    }

    #solution-detail-product-content .product-detail img{
        width: 100%;
    }
    
    #solution-detail-product-content .product-detail .product-detail-head{
        margin-top: 12%;
    }
    

    #solution-detail-product-content .product-title-benefit{
        margin-top: 12%;
    }

    #solution-detail-product-content .product-title-benefit h1{
        font-size: 22px;
    }

    #solution-detail-product-content .product-excellence{
        margin-top: 4%;
    }

    #solution-detail-product-content .product-excellence-card{
        margin-top: 4%;
    }

    #solution-detail-product-content .consultant-content{
        margin-top: 12%;
        background-color: #012E80;
        padding: 6% 0;
    }
    
    #solution-detail-product-content .consultant-content h1{
        color: var(--font-color-white);
        font-weight: 600;
        margin: auto 0;
    }
    
    #solution-detail-product-content .consultant-content .btn-consultation{
        margin-top: 6%;
        background-color: transparent;
        border: 1px solid #FFFFFF;
        font-weight: 600;
        left: 0;
        top: 0;
        transform: translate(0, 0);
        padding: 2%;
        transition: 0.3s all ease-in-out;
    }
    
    #solution-detail-product-content .consultant-content .btn-consultation:hover{
        background-color: #FFFFFF;
        border: 1px solid #FFFFFF;
        color: var(--font-color-black);
    
    }

    /* ---------- End Solution Detail Product Content ---------- */

    /* ====================================================== */


    /* ---------- END SOLUTION PAGE ---------- */

    /* ====================================================== */
    /* ====================================================== */

}