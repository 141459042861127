*{
    padding: 0;
    margin: 0;
    position: relative;
    font-family: 'Helvetica', sans-serif;
}

body, html {
    overflow-x: hidden;
}

:root{
    --font-color-white: #FFFFFF;
    --font-color-black: #020F14;
    --font-color-blue: #012E80;
    --background-color-button: #012E80;
    --background-color-retangle-one: #F5F5F5;
    --background-color-retangle-two: #012E80;
}


/* ====================================================== */

/* ---------- Navbar ---------- */

.navbar{
    padding: 10px 0;
    height: 90px;
    background-color: transparent;
    transition: background-color 0.3s ease-in-out
}

.navbar-scrolled {
    background-color: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.nav-link{
    font-size: 18px;
    margin-left: 40px;
    color: var(--font-color-white);
    font-weight: 500;
    text-decoration: none;
    list-style: none;
    transition: background-color 0.3s ease-in-out
}

.nav-link-scrolled{
    font-size: 18px;
    margin-left: 40px;
    color: var(--font-color-black);
    font-weight: 500;
    text-decoration: none;
    list-style: none;
}

/* ---------- End Navbar ---------- */

/* ====================================================== */


/* ====================================================== */

/* ---------- Main Content ---------- */

#main-content{
    position: relative;
    overflow: hidden;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

#main-content .background-main-content{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url('../images/background/bg-main-content.png');
    background-size: cover;
}

.heading-title{
    color: var(--font-color-white);
}

.heading-title .title-one h1{
    font-size: 76px;
    font-weight: 600;
}

.heading-title .title-two h5{
    margin-top: 2%;
    font-size: 34px;
    font-weight: 500;
}

.heading-title .title-three h5{
    margin-top: 4%;
    font-size: 20px;
    font-weight: 200;
}

.heading-title .btn-solution{
    margin-top: 24%;
    width: 100%;
    border-radius: 30px;
    background-color: var(--background-color-retangle-one);
    color: var(--font-color-black);
    border: 1px solid #FFFFFF;
    font-weight: 600;
    transition: 0.3s all ease-in-out;
}

.heading-title .btn-solution:hover{
    background-color: var(--background-color-button);
    border: 1px solid #012E80;
    color: var(--font-color-white);
}

.heading-title .btn-explore{
    margin-top: 24%;
    width: 100%;
    border-radius: 30px;
    background-color: transparent;
    color: var(--font-color-white);
    border: 1px solid #FFFFFF;
    font-weight: 600;
    transition: 0.3s all ease-in-out;
}

.heading-title .btn-explore:hover{
    background-color: var(--background-color-button);
    border: 1px solid #012E80;
}

/* ---------- End Main Content ---------- */

/* ====================================================== */


/* ====================================================== */

/* ---------- Problem Content ---------- */

#problem-content{
    margin-top: 6%;
}

#problem-content h1{
    font-size: 54px;
    font-weight: 600;
    text-align: center;
    color: var(--font-color-black);
}

#problem-content p{
    font-size: 24px;
    font-weight: 300;
    text-align: center;
    color: var(--font-color-black);
}

#problem-content > .container .row:nth-child(3){
    margin-top: 4%;
}

#problem-content .card{
    height: 100%;
}

#problem-content .card .problem-icon{
    width: 45px;
    border: 1px solid #012E80;
    background-color: #012E80;
    border-radius: 50%;
    margin-top: 4%;
    margin-left: 4%;
    padding: 1%;
}

#problem-content .card .card-title{
    font-size: 28px;
    font-weight: 600;
    color: var(--font-color-black);
}

#problem-content .card .card-text{
    font-size: 20px;
    color: var(--font-color-black);
    text-align: start;
}

/* ---------- End Problem Content ---------- */

/* ====================================================== */


/* ====================================================== */

/* ---------- Quotes Content ---------- */

#quotes-developing-content{
    margin-top: 6%;
    position: relative;
}

#quotes-developing-content .container .row{
    padding: 10% 0;
    margin: auto 0;
}

#quotes-developing-content .quotes-developing-background{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url('../images/background/bg-about-us.png');
    background-size: cover;
}

#quotes-developing-content .quotes-one h1{
    font-size: 40px;
    color: var(--font-color-white);
    font-weight: 600;
    text-align: center;
}

#quotes-developing-content .quotes-two h1{
    margin-top: 2%;
    font-size: 40px;
    color: var(--font-color-white);
    font-weight: 600;
    text-align: center;
}

#quotes-developing-content .quotes-three p{
    font-size: 22px;
    color: var(--font-color-white);
    text-align: center;
    font-weight: 300;
}

/* ---------- End Quotes Content ---------- */

/* ====================================================== */


/* ====================================================== */

/* ---------- About Us Content ---------- */

#about-us-content{
    margin-top: 6%;
}

#about-us-content .about-us-describe{
    margin: auto 0;
}

#about-us-content .about-us-describe hr{
    width: 14%;
    height: 2px;
    background-color: #012E80;
}

#about-us-content .about-us-describe h5{
    margin-top: 2%;
    font-weight: 600;
    color: var(--font-color-black);
}

#about-us-content .about-us-describe h2{
    margin-top: 2%;
    font-weight: 600;
    color: var(--font-color-black);
}

#about-us-content .about-us-describe p{
    margin-top: 2%;
    font-size: 20px;
    color: var(--font-color-black);
    font-weight: 300;
}

/* ---------- End About Us Content ---------- */

/* ====================================================== */


/* ====================================================== */

/* ---------- Vision & Missions Content ---------- */

#vision-missions-content{
    margin-top: 6%;
}

#vision-missions-content .vision-missions-describe h5{
    font-size: 24px;
    font-weight: 600;
}

#vision-missions-content .vision-missions-describe p{
    margin-top: 4%;
    font-size: 20px;
    font-weight: 200;
}

#vision-missions-content .vision-missions-describe button{
    margin-top: 5%;
    width: 35%;
    border-radius: 50px;
    font-weight: 500;
    font-size: 16px;
    background-color: var(--background-color-button);
    border: var(--background-color-button) 1px solid ;
}

#vision-missions-content .selected-button-outlook button{
    position: relative;
    font-size: 18px;
    width: 80%;
    border-radius: 10px;
    margin-top: 0;
    margin-bottom: 4%;
    background-color: transparent;
    border: none;
    color: var(--font-color-black);
    text-align: right;
    transition: 0.3s all ease-in-out;
}

#vision-missions-content .selected-button-outlook button:hover{
    background-color: var(--background-color-button);
    border: var(--background-color-button) 1px solid;
    color: var(--font-color-white);
}

#vision-missions-content .selected-button-outlook span{
    position: absolute;
    top: 2px; 
    left: 10%; 
    transform: translateX(-50%);
    font-size: 10px;
    color: var(--font-color-black);
}

#vision-missions-content .selected-button-outlook button:hover span{
    color: var(--font-color-white);
}

#vision-missions-content .selected-button-outlook .active{
    background-color: var(--background-color-button);
    border: var(--background-color-button) 1px solid;
    color: var(--font-color-white);
}

#vision-missions-content .selected-button-outlook .active span{
    color: var(--font-color-white);
}

/* ---------- End Vision & Missions Content ---------- */

/* ====================================================== */


/* ====================================================== */

/* ---------- Solutions Content ---------- */

#solutions-content{
    margin-top: 6%;
}

#solutions-content .solutions-title h1{
    font-size: 54px;
    font-weight: 600;
    color: var(--font-color-black);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

#solutions-content .solutions-title p{
    font-size: 22px;
    font-weight: 300;
    color: var(--font-color-black);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

#solutions-content .solutions-we-are{
    margin-top: 4%;
}

#solutions-content .solutions-we-are .solution-selection{
    padding: 1% 2%;
}

.solutions-detail-title{
    height: 100%;
    display: flex;
    align-items: flex-end;
    flex-grow: 1;
}

#solutions-content .solutions-detail-title h1{
    font-size: 30px;
    font-weight: 600;
    color: var(--font-color-white);
    margin: 0;
}

#solutions-content .solutions-detail-button{
    bottom: -20%;
}

#solutions-content .solutions-detail-button button{
    background-color: transparent;
    border-radius: 30px;
    width: 30%;
    font-weight: 600;
    color: var(--font-color-white);
    border: 1px solid #FFFFFF;
    transition: 0.3s all ease-in-out;
}

#solutions-content .solutions-we-are button:hover{
    background-color: #012E80;
    border: 1px solid #012E80;
}

/* ---------- End Solutions Content ---------- */

/* ====================================================== */


/* ====================================================== */

/* ---------- Awards & Recognitions Content ---------- */

#awards-recognitions-content{
    margin-top: 6%;
}

#awards-recognitions-content .awards-recognitions-title h1{
    font-size: 54px;
    font-weight: 600;
    color: var(--font-color-black);
}

#awards-recognitions-content .awards-recognitions-title p{
    font-size: 22px;
    font-weight: 300;
    color: var(--font-color-black);
}

#awards-recognitions-content hr{
    margin-top: 2%;
}

#awards-recognitions-content .awards-describe{
    margin-top: 2%;
}

#awards-recognitions-content .awards-describe h1{
    font-size: 28px;
    color: var(--font-color-black);
}

#awards-recognitions-content button{
    margin-top: 4%;
    background-color: var(--background-color-button);
    border: var(--background-color-button) 1px solid;
    color: var(--font-color-white);
    height: 40px;
    width: 14%;
    border-radius: 20px;
    cursor: pointer;
}

/* ---------- End Awards & Recognitions Content ---------- */

/* ====================================================== */


/* ====================================================== */

/* ---------- SDGS Title Content ---------- */

#sdgs-title-content{
    margin-top: 6%;
    position: relative;
    overflow: hidden;
    padding: 8% 0 8% 0;
}

#sdgs-title-content .sdgs-title-background{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url('../images/background/sdgs-bg.png');
    background-size: cover;
}

#sdgs-title-content h1{
    font-size: 54px;
    color: var(--font-color-white);
    text-align: center;
    font-weight: 600;
}

#sdgs-title-content p{
    margin-top: 2%;
    color: var(--font-color-white);
    text-align: center;
    font-size: 24px;
}

#sdgs-title-content .btn-sdgs-title-solutions{
    margin-top: 4%;
    width: 30%;
    border-radius: 20px;
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF;
    color: var(--font-color-black);
    font-weight: 600;
    transition: 0.3 all ease-in-out;
}

#sdgs-title-content .btn-sdgs-title-solutions:hover{
    background-color: var(--background-color-button);
    color: var(--font-color-white);
    border: 1px solid #012E80;
}

#sdgs-title-content .btn-sdgs-title-learn-more{
    margin-top: 4%;
    width: 30%;
    border-radius: 20px;
    background-color: transparent;
    border: 1px solid #FFFFFF;
    color: var(--font-color-white);
    font-weight: 600;
    transition: 0.3 all ease-in-out;
}

#sdgs-title-content .btn-sdgs-title-learn-more:hover{
    background-color: var(--background-color-button);
    color: var(--font-color-white);
    border: 1px solid #012E80;
}



/* ---------- End SDGS Title Content ---------- */

/* ====================================================== */


/* ====================================================== */

/* ---------- SDGS Describe Content ---------- */

#sdgs-describe-content{
    margin-top: 6%;
}

#sdgs-describe-content .card{
    width: 90%;
    margin: 0 auto;
}

#sdgs-describe-content .card-text{
    font-size: 20px;
    text-align: center;
    color: var(--font-color-black);
}

/* ---------- End SDGS Describe Content ---------- */

/* ====================================================== */


/* ====================================================== */

/* ---------- Impact Content ---------- */

#impact-content{
    margin-top: 6%;
}

#impact-content .impact-title-describe{
    align-items: center;
}

#impact-content h1{
    font-size: 32px;
    font-weight: 600;
    color: var(--font-color-black);
}

#impact-content p{
    margin-top: 4%;
    font-size: 24px;
    color: var(--font-color-black);
}

#impact-content .btn-see-impact{
    margin-top: 8%;
}

#impact-content .search-icon-impact{
    margin-top: -2%;
    width: 25px;
}

#impact-content span{
    font-size: 24px;
    color: var(--font-color-blue);
    font-weight: 600;
    margin-left: 2%;
}

#impact-content .card{
    height: 100%;
    border: none;
    transition: 0.3s all ease-in-out;
}

#impact-content .card:hover{
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

#impact-content .impact-icon{
    width: 80px;
    margin: 4% auto;
}

#impact-content .card-title{
    font-size: 36px;
    text-align: center;
    font-weight: 600;
    color: var(--font-color-black);
}

#impact-content .card-text{
    text-align: center;
    font-size: 20px;
    color: var(--font-color-black);
}

#impact-content .card-text span{
    font-size: 12px;
    color: var(--font-color-black);
    margin-left: -2%;
    font-weight: 600;
}

/* ---------- End Impact Content ---------- */

/* ====================================================== */


/* ====================================================== */

/* ---------- Client Content ---------- */

#client-content{
    margin-top: 6%;
}

#client-content h5{
    font-size: 24px;
    font-weight: 600;
}

#client-content .client-logo{
    margin-top: 2%;
    margin-bottom: 2%;
}

#client-content .client-logo img{
    width: 130px;
    height: 50px;
    margin-left: 20px;
    margin-right: 20px;
}

/* ---------- End Client Content ---------- */

/* ====================================================== */


/* ====================================================== */

/* ---------- Media Content ---------- */

#media-content{
    margin-top: 6%;
}

#media-content h1{
    text-align: center;
    font-size: 48px;
    font-weight: 600;
    color: var(--font-color-black);
}

#media-content p{
    margin-top: 2%;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    color: var(--font-color-black);
}


#media-content .media-card .card{
    transition: 0.3s all ease-in-out;
    border-radius: 20px;
    height: 100%;
    width: 100%;
}

#media-content .media-card .card:hover{
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

#media-content .media-card .media-describe{
    margin-top: 4%;
}

#media-content .media-card .media-image{
    border-radius: 20px 20px;
    padding: 4%;
}

#media-content .media-card .card-title{
    font-weight: 600;
}

#media-content .media-card .card-link{
    font-weight: 600;
    text-decoration: none;
    list-style: none;
    cursor: pointer;
}

/* ---------- End Media Content ---------- */

/* ====================================================== */


/* ====================================================== */

/* ---------- Contact Content ---------- */

#contact-content{
    margin-top: 6%;
}

#contact-content .contact-describe{
    padding: 4%;
    border-radius: 30px 30px 0 0;
    background-color: #2F76FF;
}

#contact-content h1{
    font-size: 48px;
    font-weight: 600;
    text-align: center;
    color: var(--font-color-white);
}

#contact-content .btn-contact{
    margin-top: 6%;
    width: 20%;
    border-radius: 20px;
    background-color: var(--background-color-button);
    border: 1px solid #012E80;
    color: var(--font-color-white);
    font-weight: 600;
    transition: 0.3 all ease-in-out;
}

#contact-content .btn-contact:hover{
    background-color: #FFFFFF;
    color: var(--font-color-black);
    border: 1px solid #FFFFFF;
}

#contact-content .btn-submit{
    background-color: #012E80;
}

/* ---------- End Contact Content ---------- */

/* ====================================================== */


/* ====================================================== */

/* ---------- Footer Content ---------- */

#footer-content{
    background-color: var(--background-color-retangle-two);
}

#footer-content .footer-describe{
    padding: 4% 0;
}

#footer-content span{
    color: var(--font-color-white);
    font-size: 24px;
    margin-left: 4%;
}

#footer-content h5{
    margin-top: 4%;
    font-size: 16px;
    color: var(--font-color-white);
    font-weight: 600;
}

#footer-content p{
    margin-top: 4%;
    color: var(--font-color-white);
}

#footer-content a{
    list-style: none;
    text-decoration: none;
    display: block;
    color: var(--font-color-white);
}

#footer-content .cp-describe{
    text-align: center;
    color: var(--font-color-white);
    
}

/* ---------- End Footer Content ---------- */

/* ====================================================== */


/* ====================================================== */
/* ====================================================== */

/* ---------- SOLUTION PAGE ---------- */


/* ====================================================== */

/* ---------- Solution Title ---------- */

#solution-title-content{
    margin-top: 6%;
    padding: 4% 0 0;
}

#solution-title-content h1{
    font-size: 54px;
    font-weight: 600;
    text-align: center;
    color: var(--font-color-black);
}

#solution-title-content h1 span{
    color: var(--font-color-white);
    background-color: var(--background-color-retangle-two);
}

#solution-title-content p{
    text-align: center;
    font-size: 24px;
    color: var(--font-color-black);
}

#solution-title-content .btn-additional{
    margin-top: 2%;
}

#solution-title-content .btn-chooseus{
    width: 25%;
    background-color: var(--background-color-button);
    border: 1px solid #012E80;
    border-radius: 20px;
    color: var(--font-color-white);
    transition: 0.3s all ease-in-out;
}

#solution-title-content .btn-chooseus:hover{
    background-color: #020F14;
    border: 1px solid #020F14;
    color: var(--font-color-white);
}

#solution-title-content .btn-contactus{
    width: 25%;
    background-color: transparent;
    border: 1px solid #020F14;
    border-radius: 20px;
    color: var(--font-color-black);
    transition: 0.3s all ease-in-out;
}

#solution-title-content .btn-contactus:hover{
    background-color: var(--background-color-button);
    border: 1px solid #012E80;
    color: var(--font-color-white);
}


/* ---------- End Solution Title ---------- */

/* ====================================================== */


/* ====================================================== */

/* ---------- Solution Card Content ---------- */

#card-solution-content{
    margin-top: 4%;
}

#card-solution-content .card-solution-describe{
    margin-top: 2%;
}

#card-solution-content .card-solution-describe .card{
    height: 100%;
    width: 100%;
}

#card-solution-content .card-solution-describe .card:hover{
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    border: none;
}

#card-solution-content .card-solution-describe .solution-icon{
    width: 50px;
    border: 1px solid #012E80;
    background-color: #012E80;
    border-radius: 50%;
    margin-top: 2%;
    margin-left: 2%;
    padding: 1%;
}

#card-solution-content .card-solution-describe .card-title{
    font-weight: 600;
    color: var(--font-color-black);
    font-size: 24px;
}

#card-solution-content .card-solution-describe .card-text{
    color: var(--font-color-black);
}

#card-solution-content .card-solution-describe .card-link{
    color: var(--font-color-blue);
    font-weight: 600;
    list-style: none;
    text-decoration: none;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
}

#card-solution-content .card-solution-describe .card-link:hover{
    border: 1px solid #012E80;
    background-color: var(--background-color-button);
    padding: 1%;
    border-radius: 20px;
    color: var(--font-color-white);
}

/* ---------- End Solution Card Content ---------- */

/* ====================================================== */


/* ====================================================== */

/* ---------- Partner Content ---------- */

#partner-content{
    margin-top: 6%;
}

#partner-content h1{
    font-size: 54px;
    font-weight: 600;
    color: var(--font-color-black);
    text-align: center;
    margin: auto 0;
}

/* ---------- End Partner Content ---------- */

/* ====================================================== */


/* ====================================================== */

/* ---------- Solution Card Content ---------- */

#card-point-solution{
    margin-top: 6%;
}

#card-point-solution .card{
    height: 100%;
}

#card-point-solution .card .card-title{
    font-size: 24px;
    font-weight: 600;
    color: var(--font-color-black);
    height: 36%;
}

#card-point-solution .card .card-text{
    color: var(--font-color-black);
}

/* ---------- End Solution Card Content ---------- */

/* ====================================================== */


/* ====================================================== */

/* ---------- Solution Detail Title Content ---------- */

#solution-detail-title-content{
    margin-top: 6%;
    padding: 2% 0;
}

#solution-detail-title-content .solution-head-main{
    margin: auto 0;
}

#solution-detail-title-content h6{
    color: var(--font-color-black);
}

#solution-detail-title-content h1{
    margin-top: 6%;
    font-size: 54px;
    font-weight: 600;
    color: var(--font-color-black);
}

#solution-detail-title-content h5{
    color: var(--font-color-black);
}

#solution-detail-title-content .btn-learn-more{
    margin-top: 4%;
    background-color: var(--background-color-button);
    border: 1px solid #012E80;
    border-radius: 20px;
    width: 20%;
    transition: 0.3s all ease-in-out;
}

#solution-detail-title-content .btn-learn-more:hover{
    background-color: transparent;
    border: 1px solid #020F14;
    color: var(--font-color-black);
}

/* ---------- End Solution Detail Title Content ---------- */

/* ====================================================== */


/* ====================================================== */

/* ---------- Solution Detail Product Content ---------- */

#solution-detail-product-content{
    margin-top: 4%;
}

#solution-detail-product-content .product-title h1{
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    color: var(--font-color-black);
}

#solution-detail-product-content .product-detail{
    margin-top: 6%;
}

#solution-detail-product-content .product-detail img{
    width: 90%;
    border-radius: 30px;
}

#solution-detail-product-content .product-detail .product-detail-head{
    margin: auto 0;
}

#solution-detail-product-content .product-detail h3{
    font-weight: 600;
    color: var(--font-color-black);
}

#solution-detail-product-content .product-detail p{
    color: var(--font-color-black);
}

#solution-detail-product-content .product-title-benefit{
    margin-top: 6%;
}

#solution-detail-product-content .product-title-benefit h1{
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    color: var(--font-color-black);
}

#solution-detail-product-content .product-excellence{
    margin-top: 2%;
}

#solution-detail-product-content .product-excellence-card{
    margin-top: 2%;
}

#solution-detail-product-content .product-excellence-card .card{
    height: 100%;
}

#solution-detail-product-content .product-excellence-card .card-title{
    font-weight: 600;
    color: var(--font-color-black);
}

#solution-detail-product-content .product-excellence-card .card-text{
    color: var(--font-color-black);
}

#solution-detail-product-content .consultant-content{
    margin-top: 6%;
    background-color: #012E80;
    padding: 4% 0;
}

#solution-detail-product-content .consultant-content h1{
    color: var(--font-color-white);
    font-weight: 600;
    margin: auto 0;
}

#solution-detail-product-content .consultant-content .btn-consultation{
    background-color: transparent;
    border: 1px solid #FFFFFF;
    font-weight: 600;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 2%;
    transition: 0.3s all ease-in-out;
}

#solution-detail-product-content .consultant-content .btn-consultation:hover{
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF;
    color: var(--font-color-black);

}

/* ---------- End Solution Detail Product Content ---------- */

/* ====================================================== */


/* ---------- END SOLUTION PAGE ---------- */

/* ====================================================== */
/* ====================================================== */